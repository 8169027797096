import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ shape }) => ({
  image: {
    borderRadius: shape.borderRadius,
    maxWidth: '100%',
  },
}));

interface ImageProps {
  alt: string;
  className?: string;
}

const Image: React.FC<ImageProps> = ({ alt, className, ...other }) => {
  const classes = useStyles();

  return (
    <img alt={alt} className={`${classes.image} ${className}`} {...other} />
  );
};

export default Image;
