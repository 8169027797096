import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DarkIcon from '@material-ui/icons/Brightness3';
import LightIcon from '@material-ui/icons/WbSunny';

export type Mode = 'light' | 'dark';

interface SwitchColorModeProps {
  onClick(mode: Mode): void;
  mode: Mode;
}

const SwitchColorMode: React.FC<SwitchColorModeProps> = ({ onClick, mode }) => {
  return (
    <Tooltip title={`Change color mode to ${mode}`}>
      <IconButton
        aria-label={`Change color mode to ${mode}`}
        onClick={() => onClick(mode === 'light' ? 'dark' : 'light')}
      >
        {mode === 'light' ? (
          <DarkIcon color="secondary" />
        ) : (
          <LightIcon color="secondary" />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default SwitchColorMode;
