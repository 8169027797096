import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import type { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ typography }) => ({
  root: {
    fontWeight: typography.fontWeightBold,
  },
}));

const Strong = ({ children }) => {
  const classes = useStyles();

  return <span className={classes.root}>{children}</span>;
};

export default Strong;
