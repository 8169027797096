import Fab from '@material-ui/core/Fab';
import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';

interface ScrollTopProps {
  className?: string;
  visibleOnDistance?: string;
}

interface StylesProps {
  visible: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>(({ spacing }) => ({
  root: {
    bottom: spacing(2),
    opacity: ({ visible }) => (visible ? 0.7 : 0),
    pointerEvents: ({ visible }) => (visible ? 'auto' : 'none'),
    position: 'fixed',
    right: spacing(2),
  },
}));

let prevCurrentPageYOffset = 0;

const ScrollTop: React.FC<ScrollTopProps> = ({ visibleOnDistance = 1000 }) => {
  const [visible, setVisibility] = useState<boolean>(false);
  const classes = useStyles({ visible });
  const ref = useRef<HTMLButtonElement>(null);

  const handleScroll = () => {
    if (!ref.current) {
      return;
    }

    const currentPageYOffset = window.pageYOffset;
    const offsetTop = ref.current ? ref.current.offsetTop : window.innerHeight;
    const scrollDirectionToTop = currentPageYOffset < prevCurrentPageYOffset;
    const distanceFromTop = currentPageYOffset + offsetTop;

    setVisibility(distanceFromTop > visibleOnDistance && scrollDirectionToTop);

    prevCurrentPageYOffset = currentPageYOffset;
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const handleClick = () => {
    scrollTop();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Fab
      aria-label="Scroll top"
      className={classes.root}
      color="secondary"
      onClick={handleClick}
      ref={ref}
    >
      <ArrowUpward />
    </Fab>
  );
};

export default ScrollTop;
