import Highlight, { defaultProps, Language } from 'prism-react-renderer';
import React, { useCallback, useEffect, useState } from 'react';
import theme from 'prism-react-renderer/themes/oceanicNext';
import copy from 'copy-to-clipboard';
import Edit from '@material-ui/icons/Edit';
import FileCopy from '@material-ui/icons/FileCopy';
import Done from '@material-ui/icons/Done';
import {
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';

import useLazyFetch from '../../../../hooks/useLazyFetch';

import WiderContentControl from '../WiderContentControl';

import getCodeSandboxConfig from './getCodeSandboxConfig';
import initCodeParser, { cleanupHiddenCode } from './commentCodeParser';

const useStyles = makeStyles<Theme>(
  ({ breakpoints, spacing, typography, shape }) => ({
    root: {
      position: 'relative',
    },
    pre: {
      fontFamily: 'source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace',
      fontSize: 14,
      margin: spacing(0.25, 0, 4),
      overflow: 'auto',
      padding: spacing(4, 2),
      position: 'relative',

      [breakpoints.up('sm')]: {
        borderRadius: shape.borderRadius,
        padding: spacing(4),
      },

      '&::before': {
        background: '#fafafa',
        borderRadius: 'inherit',
        color: '#2a2734',
        content: 'attr(data-language)',
        fontSize: 12,
        fontWeight: typography.fontWeightBold,
        lineHeight: 1,
        opacity: '.35',
        padding: spacing(0.5, 1),
        position: 'absolute',
        right: spacing(1),
        textTransform: 'uppercase',
        top: spacing(1),
      },

      '& .highlighted': {
        background: '#323a47',
        borderRadius: shape.borderRadius,
        display: 'inline-block',
        margin: spacing(0, -2),
        padding: spacing(1, 2),
      },

      '& .hide': {
        display: 'none',
        userSelect: 'none',
      },
    },
  })
);

interface CodeProps {
  children: string;
  className: string;
  csId?: string;
  description?: string;
  edit?: boolean;
  noCopy?: boolean;
  title?: string;
}

const url = 'https://codesandbox.io/';

const Code: React.FC<CodeProps> = ({
  children,
  className,
  csId,
  description = '',
  edit = false,
  title = '',
  noCopy = false,
}) => {
  const [isCopied, setCopied] = useState(false);
  const code = children?.trim();
  const parseComments = useCallback(initCodeParser(), [code]);
  const classes = useStyles();
  const language = className?.replace(/language-/, '') as Language;
  const [fetchSandbox, { loading, error, response }] = useLazyFetch(
    `${url}api/v1/sandboxes/define?json=1`
  );
  const id = response?.sandbox_id;

  useEffect(() => {
    if (id) {
      window.open(
        `${url}s/${id}?runonclick=1&file=/Demo.${language}`,
        '_blank'
      );
    }
  }, [id]);

  const copyCode = () => {
    copy(cleanupHiddenCode({ code, allowCodeRemove: true }));

    if (!isCopied) {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const handleOpenSandbox = () => {
    if (csId) {
      return window.open(`${url}s/${csId}?runonclick=1`, '_blank');
    }

    fetchSandbox(
      getCodeSandboxConfig({
        code: cleanupHiddenCode({ code }),
        description: description.replace(/_/g, ' '),
        language,
        title: title.replace(/_/g, ' '),
        homepage: `${url}s/${id}`,
      })
    );
  };

  return (
    <WiderContentControl>
      <div className={classes.root}>
        <Grid container justify="space-between" alignItems="center">
          <Grid />
          <Grid item>
            {edit && (
              <Tooltip
                title="Open in CodeSandbox"
                aria-label="Open in CodeSandbox"
              >
                <IconButton
                  disabled={!!error || loading}
                  onClick={handleOpenSandbox}
                >
                  {loading ? (
                    <CircularProgress color="secondary" size={24} />
                  ) : (
                    <Edit fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {!noCopy && (
              <Tooltip title="Copy the source" aria-label="Copy the source">
                <IconButton onClick={copyCode}>
                  {isCopied ? (
                    <Done fontSize="small" />
                  ) : (
                    <FileCopy fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Highlight
          {...defaultProps}
          theme={theme}
          code={code}
          language={language}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre
              className={`${className} ${classes.pre}`}
              style={{ ...style }}
              data-language={language}
            >
              {tokens.map((line, i) => {
                const lineProps = getLineProps({ line, key: i });
                const directivesBasedClasses = parseComments(line);
                const finalLineProps = {
                  ...lineProps,
                  className: [
                    lineProps.className,
                    ...directivesBasedClasses,
                  ].join(' '),
                };

                return (
                  <div key={i} {...finalLineProps}>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                );
              })}
            </pre>
          )}
        </Highlight>
      </div>
    </WiderContentControl>
  );
};

export default Code;
