import { Helmet } from 'react-helmet';
import React from 'react';

import * as graphs from './graphs';

import { TechArticle, Person, Organization } from './graphs';

interface SchemaProps {
  person?: Person;
  organization?: Organization;
  techArticle?: TechArticle;
}

const Schema: React.FC<SchemaProps> = props => {
  const graphsArr = Object.keys(props);

  if (!graphsArr.length) {
    return null;
  }

  const finalGraph = graphsArr.reduce(
    (graphsAcc, graphType) => {
      const graph = graphs[graphType];

      if (graph) {
        return [...graphsAcc, graphs[graphType](props[graphType])];
      }

      return graphsAcc;
    },
    [] as any
  );

  return (
    <Helmet>
      <html lang="en" prefix="og: http://ogp.me/ns#" />
      <script type="application/ld+json">
        {`{
        "@context": "http://schema.org",
        "@graph": ${JSON.stringify(finalGraph)}
      }`}
      </script>
    </Helmet>
  );
};

export default Schema;
