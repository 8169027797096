import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import type { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  root: {
    position: 'relative',
    marginBottom: spacing(3),
  },
}));

const Headline = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} variant="body1">
      {children}
    </Typography>
  );
};

export default Headline;
