import Container from '@material-ui/core/Container';
import GatsbyImage from 'gatsby-image';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';

import Breadcrumb from '../components/Breadcrumb';
import MDXProviderExtended from '../components/MDXProviderExtended';
import PostFooter from '../components/PostFooter';
import PostMeta from '../components/PostMeta';
import PostShare from '../components/PostShare';
import Schema from '../components/Schema';
import ScrollTop from '../components/ScrollTop';
import type { GatsbyPageProps } from '../types';
import Seo from '../components/Seo';
import SubscribeForm from '../components/SubscribeForm';
import CtaBox from '../components/CtaBox';
import SwitchColorMode from '../components/SwitchColorMode';
import type { Mode } from '../components/SwitchColorMode';

const useStyles = makeStyles<Theme, { mode: Mode }>(
  ({ breakpoints, palette, spacing, typography, shape }) => ({
    article: {
      background: ({ mode }) =>
        palette.common[mode === 'light' ? 'white' : 'black'],
      color: ({ mode }) => palette.common[mode === 'light' ? 'black' : 'white'],
    },
    header: {
      alignItems: 'center',
      padding: spacing(10, 0, 3),
    },
    cover: {
      borderRadius: shape.borderRadius,
      height: 'auto',
      margin: spacing(0, 0, 4),
      width: '100%',
    },
    content: {
      padding: spacing(2, 0),
    },
    container: {
      '& > p:first-child': {
        fontSize: typography.h4.fontSize,
      },
      '& ol, & ul': {
        paddingLeft: spacing(2),
        margin: spacing(3, 0),

        [breakpoints.up('md')]: {
          paddingLeft: spacing(4),
        },
      },
      '& li': {
        marginBottom: spacing(1),
      },
    },
  })
);

const getSavedMode = () => {
  const defaultMode = 'light';

  if (typeof window === 'undefined') {
    return 'light';
  }

  return (localStorage.getItem('pf-mode') as Mode) || defaultMode;
};

type Data = any;

const PostTemplate: React.FC<GatsbyPageProps<Data>> = ({ data = {} }) => {
  const [mode, setMode] = useState<Mode>(getSavedMode);
  const classes = useStyles({ mode });
  const { site = {}, mdx = {} } = data;
  const { siteMetadata = {} } = site;
  const { siteUrl, siteName } = siteMetadata;
  const { frontmatter, timeToRead, wordCount, fields } = mdx;
  const {
    banner,
    categories,
    datePublished,
    dateModified,
    dateModifiedHuman,
    datePublishedHuman,
    h1,
    keywords,
    title,
  } = frontmatter;
  const author = frontmatter.author || siteMetadata.author;
  const authorAvatar = frontmatter.authorAvatar || siteMetadata.authorAvatar;
  const authorLink = frontmatter.authorLink || siteMetadata.authorLinks[0].href;
  const bannerFluid = banner.childImageSharp.fluid;
  const description = frontmatter.description || mdx.excerpt;
  const path = fields.slug;

  const handleSwitchMode = (mode: Mode) => {
    setMode(mode);
    localStorage.setItem('pf-mode', mode);
  };

  return (
    <>
      <Seo
        articleAuthor={authorLink}
        articleModified={dateModified}
        articlePublished={datePublished}
        articleSection={categories[0]}
        articleTag={keywords[0]}
        description={description}
        ogImageContent={banner.childImageSharp.fluid.src}
        ogImageHeight={banner.childImageSharp.fluid.presentationHeight}
        ogImageWidth={banner.childImageSharp.fluid.presentationWidth}
        ogType="article"
        path={path}
        title={title}
      />
      <Schema
        techArticle={{
          authorImageUrl: authorAvatar,
          authorName: author,
          authorSameAs: [authorLink],
          dateCreated: datePublished,
          dateModified: dateModified || datePublished,
          datePublished,
          description,
          editor: author,
          headline: title,
          image: `${siteUrl}${bannerFluid.src}`,
          keywords,
          logoCaption: siteName,
          logoUrl: `${siteUrl}/logo.png`,
          path,
          siteUrl,
          wordCount: wordCount.words,
        }}
      />
      <MDXProviderExtended>
        <article className={classes.article}>
          <header className={classes.header}>
            <Container maxWidth="md">
              <Box textAlign="center" mt={-1} mb={1}>
                <SwitchColorMode onClick={handleSwitchMode} mode={mode} />
              </Box>
              <Breadcrumb
                justifyContent="center"
                data={[
                  {
                    href: '/',
                    title: 'homepage',
                  },
                  {
                    href: '/blog',
                    title: 'blog',
                  },
                ]}
              />

              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                align="center"
              >
                {h1 || title}
              </Typography>
              <Box mt={4}>
                <PostMeta
                  author={author}
                  authorAvatar={authorAvatar}
                  authorLink={authorLink}
                  dateModified={dateModified || datePublished}
                  dateModifiedHuman={dateModifiedHuman || datePublishedHuman}
                  datePublished={datePublished}
                  datePublishedHuman={datePublishedHuman}
                  timeToRead={timeToRead}
                />
              </Box>
            </Container>
          </header>
          <div className={classes.content}>
            <Container maxWidth="md" className={classes.container}>
              {banner && (
                <GatsbyImage
                  alt={`${categories.join(' ')} - banner`}
                  className={classes.cover}
                  fluid={bannerFluid}
                />
              )}
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
              <Box my={6}>
                <PostShare url={`${siteUrl}${path}`} text={title} />
              </Box>
              <Box mb={6}>
                <CtaBox>
                  <SubscribeForm />
                </CtaBox>
              </Box>
            </Container>
          </div>
          <PostFooter siteName={siteName} />
        </article>
      </MDXProviderExtended>
      <ScrollTop />
    </>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query PostTemplate($id: String) {
    site {
      siteMetadata {
        author
        authorAvatar
        authorLinks {
          href
          label
        }
        siteName
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      excerpt
      timeToRead
      wordCount {
        words
      }
      frontmatter {
        h1
        author
        authorAvatar
        authorLink
        categories
        datePublished: dateCreated
        dateModifiedHuman: dateModified(formatString: "MMMM Do, YYYY")
        datePublishedHuman: dateCreated(formatString: "MMMM Do, YYYY")
        dateModified
        description
        keywords
        title
        banner {
          childImageSharp {
            fluid(maxWidth: 755, quality: 90) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
