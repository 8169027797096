import React from 'react';

import Link from '../../Link';

import Blockquote from './Blockquote';
import Code from './Code';
import DetailsPanel from './DetailsPanel';
import Headline from './Headline';
import Paragraph from './Paragraph';
import Strong from './Strong';
import Tabs from './Tabs';
import TsJsCode from './TsJsCode';
import Image from './Image';

export default {
  h1: () => {
    throw new Error('<h1 /> already defined in header.');
  },
  h2: (props) => <Headline variant="h2" {...props} />,
  h3: (props) => <Headline variant="h3" {...props} />,
  h4: (props) => <Headline variant="h4" {...props} />,
  h5: (props) => <Headline variant="h5" {...props} />,
  h6: (props) => <Headline variant="h6" {...props} />,
  p: Paragraph,
  a: (props) => <Link underlined {...props} />,
  blockquote: Blockquote,
  Tabs,
  TsJsCode,
  code: Code,
  pre: ({ children }) => children,
  Code,
  DetailsPanel,
  strong: Strong,
  img: Image
};
