import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Variant } from '@material-ui/core/styles/createTypography';

import WiderContentControl from './WiderContentControl';

const useStyles = makeStyles<Theme, { expanded: boolean; bgColor: string }>(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      background: ({ bgColor }) => bgColor,
      position: 'relative',
      color: 'inherit',

      '&&&': {
        border: 'none',
        borderRadius: 0,
        marginBottom: spacing(4),
      },

      '&::after': {
        background: palette.secondary.light,
        content: '" "',
        height: `calc(100% - ${spacing(2)}px)`,
        left: 0,
        position: 'absolute',
        top: spacing(1),
        width: 5,

        [breakpoints.up('lg')]: {
          left: spacing(-1),
        },
      },
    },
    panelSummary: {
      '@media (hover: hover)': {
        '&:hover': {
          background: ({ expanded }: any) =>
            expanded ? 'transparent' : 'rgba(68, 138, 255, 0.3)',
        },
      },
    },
    label: {
      fontWeight: typography.fontWeightBold,
    },
    content: {
      display: 'block',
    },
  })
);

interface TogglePanelProps {
  bgColor?: string;
  defaultExpanded?: boolean;
  disableBtmCloseBtn?: boolean;
  label: string;
  labelVariant?: Variant;
}

const DetailsPanel: React.FC<TogglePanelProps> = ({
  bgColor = 'transparent' ,
  children,
  defaultExpanded = false,
  disableBtmCloseBtn = false,
  label,
  labelVariant = undefined,
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const classes = useStyles({ bgColor, expanded });
  const id = label.toLowerCase().replace(/\s/g, '-');

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <WiderContentControl>
      <ExpansionPanel
        className={classes.root}
        defaultExpanded={defaultExpanded}
        elevation={0}
        expanded={expanded}
        onChange={toggleExpanded}
        variant="outlined"
        id={id}
      >
        <ExpansionPanelSummary
          aria-controls={`${id}-panel-content`}
          expandIcon={<ExpandMore color="secondary" />}
          id={`${id}-panel-header`}
          className={classes.panelSummary}
        >
          <Typography className={classes.label} variant={labelVariant}>
            {label}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          className={classes.content}
          id={`${id}-panel-content`}
        >
          {children}
          {!disableBtmCloseBtn && (
            <Box mt={3} mr={-1} display="flex" justifyContent="flex-end">
              <Tooltip title="Close panel" aria-label="Close panel">
                <IconButton
                  aria-controls={`${id}-panel-content`}
                  aria-label="Collapse details"
                  onClick={toggleExpanded}
                  href={`#${id}`}
                >
                  <ExpandLess />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </WiderContentControl>
  );
};

export default DetailsPanel;
