import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({  spacing, typography }) => ({
  '@global': {
    code: {
      background: 'rgba(68, 138, 255, 0.4)',
      borderRadius: 5,
      fontFamily: 'source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace',
      fontSize: typography.caption.fontSize,
      padding: spacing(0.25, 0.5),
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      color: 'inherit',
    },
  },
}));

const CodeInline = () => {
  useStyles();

  return null;
};

export default CodeInline;
