import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';

import Logo from './Logo';
import TextGradient from './TextGradient';
import Box from '@material-ui/core/Box';

const year = new Date().getFullYear();

const useStyles = makeStyles<Theme>(({ palette, spacing }) => ({
  root: {
    background: palette.background.default,
    alignItems: 'center',
    display: 'flex',
    padding: spacing(6, 2),
    flexWrap: 'wrap',
    flexDirection: 'column',
    color: palette.common.white,
  },
}));

interface PostFooterProps {
  siteName: string;
}

const PostFooter: React.FC<PostFooterProps> = ({ siteName }) => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Typography variant="h5">
        <GatsbyLink to="/">
          <img
            alt="trident"
            src="/logo.svg"
            style={{ height: '21px', marginRight: 8 }}
          />
        </GatsbyLink>
        <img
          alt="trident"
          src="/images/ukraine/trident.svg"
          style={{ height: '21px', marginRight: 8 }}
        />
        Glory to <TextGradient>Ukraine!</TextGradient>
      </Typography>
    </footer>
  );
};

export default PostFooter;
