import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import type { Theme } from '@material-ui/core';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

import WiderContentControl from './WiderContentControl';

const useStyles = makeStyles<Theme>(({ spacing, palette, typography }) => ({
  root: {
    background: 'rgba(44,119,250,0.05)',
    borderLeft: `5px solid ${palette.secondary.light}`,
    margin: 0,
    marginBottom: spacing(4),
    padding: spacing(3, 2, 2),
    position: 'relative',

    '& p': {
      margin: 0,
      color: 'inherit',
      fontStyle: 'italic',
    },

    '& cite': {
      fontSize: typography.caption.fontSize,
      color: palette.grey.A700,

      '&::before': {
        content: '"-"',
        marginRight: spacing(0.5),
      },
    },
  },
  quote: {
    color: palette.secondary.light,
    fontSize: 60,
    right: spacing(1),
    position: 'absolute',
    top: -30,
  },
}));

const Blockquote = ({ children }) => {
  const classes = useStyles();

  return (
    <WiderContentControl>
      <blockquote className={classes.root}>
        <FormatQuoteIcon className={classes.quote} />
        {children}
      </blockquote>
    </WiderContentControl>
  );
};

export default Blockquote;
