import Box from '@material-ui/core/Box';
import MuiTabs from '@material-ui/core/Tabs';
import React, { Children } from 'react';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

import WiderContentControl from './WiderContentControl';

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabs: {
    border: `1px rgba(68, 138, 255, 0.5) solid`,
    background: 'rgba(68, 138, 255, 0.3)',

    [breakpoints.down('md')]: {
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  tab: {
    minWidth: 'auto',
  },
}));

interface TabsProps {
  labels: string[];
}

const createId = (str: string) => str?.toLowerCase().replace(/\s/g, '-');

const Tabs: React.FC<TabsProps> = ({ children, labels }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <WiderContentControl>
        <MuiTabs
          className={classes.tabs}
          aria-label="Content tabs"
          onChange={handleChange}
          scrollButtons="auto"
          value={value}
          variant="scrollable"
        >
          {labels?.map((label, index) => {
            const id = createId(labels[index]);

            return (
              <Tab
                aria-controls={`tabpanel-${id}`}
                id={`tab-${id}`}
                key={`tab-${id}`}
                label={label}
                className={classes.tab}
              />
            );
          })}
        </MuiTabs>
      </WiderContentControl>
      {Children.map(children, (child, index) => {
        const id = createId(labels[index]);

        return (
          <Box
            aria-labelledby={`tab-${id}`}
            hidden={value !== index}
            id={`tabpanel-${id}`}
            py={2}
            role="tabpanel"
          >
            {value === index && child}
          </Box>
        );
      })}
    </div>
  );
};

export default Tabs;
