import React, { Children } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(
  ({ spacing, palette, typography, shape }) => ({
    root: {
      position: 'relative',
    },
    tabs: {
      minHeight: 'auto',
      position: 'absolute',
      top: 0,
      zIndex: 1,
    },
    tab: {
      background: palette.grey[200],
      border: `1px ${palette.grey[400]} solid`,
      fontSize: typography.caption.fontSize,
      minHeight: 'auto',
      minWidth: 'auto',
      padding: spacing(0.25, 2),
      color: palette.common.black,
      borderRadius: shape.borderRadius,
      marginRight: 2,
    },
    indicator: {
      background: 'transparent',
      bottom: 'auto',
      top: 0,

      '&:after': {
        background: palette.secondary.light,
        borderRadius: '50%',
        top: 3,
        content: '""',
        height: 6,
        right: 3,
        marginLeft: -3,
        position: 'absolute',
        width: 6,
      },
    },
  })
);

const TsJsCode = ({ children }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={handleChange}
        aria-label="Code tabs"
        TabIndicatorProps={{ className: classes.indicator }}
      >
        {Children.map(children, (child, index) => {
          const label = child.props.children.props.className.replace(
            /language-/,
            ''
          );

          return (
            <Tab
              className={classes.tab}
              label={label}
              id={`code-tab-${index}`}
              aria-controls={`code-tabpanel-${index}`}
            />
          );
        })}
      </Tabs>
      {Children.map(children, (child, index) => (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`code-tabpanel-${index}`}
          aria-labelledby={`code-tab-${index}`}
        >
          {value === index && child}
        </div>
      ))}
    </div>
  );
};

export default TsJsCode;
