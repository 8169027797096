const defaultInfo = 'Demo';

export default ({
  code,
  language,
  title = defaultInfo,
  description = defaultInfo,
  homepage,
}) => {
  const isTs = language === 'tsx';

  const tsDependencies = isTs && {
    '@types/react-dom': 'latest',
    '@types/react': 'latest',
  };

  const files = {
    'package.json': {
      content: {
        name: `@webman-pro/${title.toLowerCase().replace(/\s/g, '-')}`,
        title: `${title} - webman.pro`,
        description: `${description} - webman.pro`,
        version: '1.0.0',
        main: `index.${language}`,
        homepage,
        dependencies: {
          react: 'latest',
          'react-dom': 'latest',
        },
        devDependencies: {
          'prop-types': 'latest',
          typescript: 'latest',
          ...tsDependencies,
        },
      },
    },
    '.prettierrc': {
      content: {
        semi: true,
        singleQuote: true,
        trailingComma: 'es5',
        tabWidth: 2,
      },
    },
    'index.html': {
      content: `
<body>
  <div id="root"></div>
</body>
      `,
    },
    [`index.${language}`]: {
      content: `
import React from 'react';
import { render } from 'react-dom';

import { Demo } from './Demo';

const rootElement = document.getElementById('root');

render(
  <React.StrictMode>
    <Demo />
  </React.StrictMode>,
  rootElement
);
      `,
    },
    [`Demo.${language}`]: { content: code },
  };

  if (isTs) {
    files['tsconfig.json'] = {
      content: {
        compilerOptions: {
          target: 'es5',
          strict: true,
          skipLibCheck: true,
          noImplicitAny: true,
          noEmit: true,
          module: 'esnext',
          lib: ['dom', 'dom.iterable', 'esnext'],
          jsx: 'preserve',
          isolatedModules: true,
          forceConsistentCasingInFileNames: true,
          esModuleInterop: true,
          allowSyntheticDefaultImports: true,
          allowJs: true,
        },
      },
    };
  }

  return { files };
};
