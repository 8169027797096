import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';

import Link  from '../../Link';

const useStyles = makeStyles<Theme>(({ palette, transitions }) => ({
  root: {
    position: 'relative',
    margin: '3rem 0 1.5rem',

    '&::before': {
      color: palette.grey[300],
      content: '"#"',
      fontSize: '0.5em',
      left: 0,
      position: 'absolute',
      top: 0,
      transform: 'translateX(-110%)',
      transition: `color ${transitions.duration.shorter}ms`,
    },

    '&:hover': {
      '&::before': {
        color: palette.secondary.light,
      },
    },
  },
}));

interface HeadlineProps {
  children: string;
  variant: Variant;
}

const Headline: React.FC<HeadlineProps> = ({ children, variant }) => {
  const classes = useStyles();
  const id = children
    .toLocaleLowerCase()
    .replace(/[^\w\s]/gi, '')
    .replace(/\s/g, '-');

  return (
    <Typography className={classes.root} id={id} variant={variant}>
      <Link color="inherit" bold noUnderline href={`#${id}`}>
        {children}
      </Link>
    </Typography>
  );
};

export default Headline;
