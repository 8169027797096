import React from 'react';
import { MDXProvider } from '@mdx-js/react';

import components from './components';
import CodeInline from './components/CodeInline';

const MDXProviderExtended: React.FC<{}> = ({ children }) => (
  <>
    <MDXProvider components={components}>{children}</MDXProvider>
    <CodeInline />
  </>
);
export default MDXProviderExtended;
