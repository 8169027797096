import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  root: {
    margin: spacing(0, -2, ),

    [breakpoints.up('lg')]: {
      margin: spacing(0, -3),
    },
  },
}));

const WiderContentControl: React.FC<{}> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default WiderContentControl;
