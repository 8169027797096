export interface Organization {
  email: string;
  logoCaption: string;
  logoUrl: string;
  name: string;
  sameAs: string[];
  siteUrl: string;
}

export const organization = ({
  email,
  logoCaption,
  logoUrl,
  name,
  sameAs,
  siteUrl,
}: Organization) => ({
  '@type': 'Organization',
  name,
  url: siteUrl,
  email,
  sameAs,
  logo: {
    '@type': 'ImageObject',
    url: logoUrl,
    width: 60,
    height: 50,
    caption: logoCaption,
  },
});

export interface Person {
  description?: string;
  imageUrl: string;
  name: string;
  sameAs: string[];
}

export const person = ({ name, imageUrl, description, sameAs }: Person) => ({
  '@type': 'Person',
  name,
  image: {
    '@type': 'ImageObject',
    url: imageUrl,
    caption: name,
  },
  description,
  sameAs,
});

export interface TechArticle {
  authorImageUrl: string;
  authorName: string;
  authorSameAs: string[];
  dateCreated: string;
  dateModified: string;
  datePublished: string;
  description: string;
  editor: string;
  headline: string;
  image: string;
  keywords: string;
  logoCaption: string;
  logoUrl: string;
  path: string;
  proficiencyLevel?: string;
  siteUrl: string;
  wordCount?: number;
}

export const techArticle = ({
  authorImageUrl,
  authorName,
  authorSameAs,
  dateCreated,
  dateModified,
  datePublished,
  description,
  editor,
  headline,
  image,
  keywords,
  logoCaption,
  logoUrl,
  path,
  proficiencyLevel = 'Expert',
  siteUrl,
  wordCount,
}: TechArticle) => ({
  '@type': 'TechArticle',
  author: person({
    imageUrl: authorImageUrl,
    name: authorName,
    sameAs: authorSameAs,
  }),
  dateModified,
  datePublished,
  dateCreated,
  description,
  editor,
  headline,
  image,
  proficiencyLevel,
  keywords,
  wordCount,
  publisher: {
    '@type': 'Organization',
    name: 'webman.pro',
    logo: {
      '@type': 'ImageObject',
      url: logoUrl,
      width: 60,
      height: 50,
      caption: logoCaption,
    },
  },
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': `${siteUrl}${path}`,
  },
});
