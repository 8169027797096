import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

import Link from './Link';

const AVATAR_SIZE = 48;

const useStyles = makeStyles<Theme>(({ palette }) => ({
  root: {
    lineHeight: 1,
  },
  avatarWrapper: {
    borderRadius: AVATAR_SIZE,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: palette.secondary.light,
    padding: 2,
    display: 'flex',

    '@media (hover: hover)': {
      '&:hover': {
        '& img': {
          transform: 'scaleX(-1)',
        },
      },
    },

    '& img': {
      borderRadius: 'inherit',
      height: AVATAR_SIZE,
      width: AVATAR_SIZE,
    },
  },
  label: {
    color: palette.grey[600],
    fontSize: '0.8rem',
  },
}));

interface PostMetaProps {
  author: string;
  authorAvatar: string;
  authorLink?: string;
  dateModified: string;
  dateModifiedHuman: string;
  datePublished: string;
  datePublishedHuman: string;
  timeToRead: string;
}

const PostMeta: React.FC<PostMetaProps> = ({
  author,
  authorAvatar,
  authorLink,
  dateModified,
  dateModifiedHuman,
  datePublished,
  datePublishedHuman,
  timeToRead,
}) => {
  const classes = useStyles();

  return (
    <Grid alignItems="center" className={classes.root} container spacing={1}>
      {authorAvatar && (
        <Grid item>
          <Link
            href={authorLink}
            rel="noreferrer noopener nofollow"
            target="_blank"
            noUnderline
            className={classes.avatarWrapper}
          >
            <img src={authorAvatar} alt={author} />
          </Link>
        </Grid>
      )}
      <Grid item>
        <Typography variant="caption">
          {authorLink ? (
            <Link
              href={authorLink}
              rel="noreferrer noopener nofollow"
              target="_blank"
              noUnderline
            >
              {author}
            </Link>
          ) : (
            author
          )}
        </Typography>
        <Typography component="div" className={classes.label}>
          <Tooltip
            title={
              dateModifiedHuman && datePublishedHuman !== dateModifiedHuman
                ? `Modified on ${dateModifiedHuman}`
                : ''
            }
          >
            <time dateTime={datePublished}>{datePublishedHuman}</time>
          </Tooltip>
        </Typography>
      </Grid>
      <div style={{ display: 'flex', flexGrow: 1 }} />
      <Grid item>
        <Typography component="div" className={classes.label}>
          {Math.round(Number(timeToRead) * 1.5)} min read
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PostMeta;
