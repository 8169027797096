import { Facebook, Twitter, Reddit, RssFeed } from '@material-ui/icons';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

import Link from './Link';

const useStyles = makeStyles<Theme>(
  ({ spacing, palette, shape, typography, breakpoints }) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',

      [breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
      },
    },
    message: {
      border: `2px ${palette.secondary.light} solid`,
      color: palette.secondary.light,
      padding: spacing(2),
      flexGrow: 1,
    },
    socialContainer: {
      padding: spacing(1),
      background: palette.secondary.light,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,

      [breakpoints.up('sm')]: {
        flexGrow: 0,
      },

      '& a': {
        color: palette.common.white,
        padding: spacing(1),
        display: 'flex',
        justifyContent: 'center',

        '@media (hover: hover)': {
          '&:hover': {
            color: palette.secondary.dark,
          },
        },

        [breakpoints.up('sm')]: {
          flex: '50%',
          padding: spacing(1, 0),
        },
      },
    },
  })
);

interface PostShareProps {
  url: string;
  text: string;
}

const createTwitterUrl = ({ text, url, nickname }) =>
  `https://twitter.com/intent/tweet?text=${text}&url=${url}&via=${nickname}`;

const links = [
  {
    createUrl: ({ url, text }) =>
      `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`,
    icon: <Facebook fontSize="large" color="inherit" />,
    name: 'Facebook',
  },
  {
    createUrl: createTwitterUrl,
    icon: <Twitter fontSize="large" />,
    name: 'Twitter',
  },
  {
    createUrl: ({ url, text }) =>
      `https://www.reddit.com/submit?url=${url}&title=${text}`,
    icon: <Reddit fontSize="large" />,
    name: 'Reddit',
  },
];

const PostShare: React.FC<PostShareProps> = ({ url, text }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.message}>
        <Typography variant="h6" component="p">
          Do you like the article or have some remarks?{' '}
          <Link
            href={`${createTwitterUrl({ url, text, nickname: 'wwwebman' })}`}
            underlined
          >
            Let's discuss it!
          </Link>
        </Typography>
      </div>
      <div className={classes.socialContainer}>
        {links.map((item) => {
          const href = item.createUrl({ url, text, nickname: 'wwwebman' });

          return (
            <Link
              href={href}
              key={href}
              noUnderline
              title={`Share on ${item.name}`}
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              {item.icon}
            </Link>
          );
        })}
        <Link href="/blog/rss.xml" title="RSS feed" noUnderline>
          <RssFeed fontSize="large" />
        </Link>
      </div>
    </div>
  );
};

export default PostShare;
