import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

import Link from '../components/Link';

const useStyles = makeStyles<Theme, Partial<BreadcrumbProps>>(
  ({ typography, spacing, palette }) => ({
    root: {
      display: 'flex',
      justifyContent: ({ justifyContent }) => justifyContent || 'flex-start',
      listStyle: 'none',
      margin: spacing(0, -1),
    },
    item: {
      fontSize: typography.caption.fontSize,
      color: palette.grey.A100,

      '&:not(:last-child)::after': {
        content: '"/"',
      },
    },
    link: {
      padding: spacing(1),
    },
  })
);

interface Item {
  href: string;
  title: string;
}

interface BreadcrumbProps {
  data: Item[];
  justifyContent?: 'flex-start' | 'flex-end' | 'center';
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ data, justifyContent }) => {
  const classes = useStyles({ justifyContent });

  return (
    <ul
      className={classes.root}
      itemScope
      itemType="http://schema.org/BreadcrumbList"
    >
      {data.map((item, index) => (
        <li
          key={item.title}
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
          className={classes.item}
        >
          <Link
            className={classes.link}
            to={item.href}
            itemProp="item"
            noUnderline
          >
            <span itemProp="name">{item.title}</span>
            <meta itemProp="position" content={`${index + 1}`} />
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumb;
