const directives = {
  hideStart: '// hide-start',
  hideEnd: '// hide-end',
  highlightNext: '// highlight-next-line',
};

interface CleanupHiddenCode {
  code: string;
  allowCodeRemove?: boolean;
}

export const cleanupHiddenCode = ({
  code,
  allowCodeRemove = false,
}: CleanupHiddenCode): string => {
  let regexSting = Object.values(directives).join('|');

  if (allowCodeRemove) {
    regexSting = `${directives.hideStart}[\\s\\S]*?${directives.hideEnd}|${regexSting}`;
  }

  const commentsRegex = new RegExp(regexSting, 'g');

  return code.replace(commentsRegex, '');
};

export default () => {
  let prevDirective;

  return (line) => {
    const classes: string[] = [];
    const currentDirective = line.filter((l) =>
      Object.values(directives).includes(l.content)
    )?.[0]?.content;

    if (
      prevDirective === directives.hideStart ||
      currentDirective === directives.highlightNext ||
      currentDirective === directives.hideStart ||
      currentDirective === directives.hideEnd
    ) {
      classes.push('hide');
    }

    if (prevDirective === directives.highlightNext) {
      classes.push('highlighted');
    }

    prevDirective = currentDirective;

    return classes;
  };
};
